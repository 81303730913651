import styled from "@emotion/styled";
import EastIcon from "@mui/icons-material/East";
import ArrowGIF from "../images/pil.gif";

export const Paragraph = styled.p`
  font-size: 1.2rem;
  font-family: "PPNeueMontreal-Bold" !important;
  margin-block: 12px;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

const GIF = styled.img`
  height: 100%;
  width: auto;
`;
const Arrow = styled(EastIcon)`
  font-weight: 400;
`;

type ButtonContentProps = {
  loading?: boolean;
};

export const RSVPButtonContent = ({ loading = false }: ButtonContentProps): JSX.Element => {
  return (
    <>
      <Arrow />
      {/* <GIF src={ArrowGIF} /> */}
      <Paragraph>{loading ? "loading..." : "rsvp"}</Paragraph>
    </>
  );
};
