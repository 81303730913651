import React, { useState } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import {
  Box,
  Checkbox as CheckboxUI,
  FormControl,
  FormHelperText as FormHelperTextUI,
  Input as InputUI,
  InputLabel,
} from "@mui/material";
import { Button, RSVPButtonContent } from "./util/common";
import axios from "axios";
import * as yup from "yup";
import { addDoc, collection } from "firebase/firestore";
import { db } from "./firebase";

const validationSchema = yup.object({
  firstName: yup.string().required("first name is required"),
  lastName: yup.string().required("last name is required"),
  email: yup.string().email("enter a valid email").required("email is required"),
});

type FormikFormProps = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

export const FormikForm = ({ setStep }: FormikFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      hasGuest: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const addToGuestlist = async (values: any) => {
    const { firstName, lastName, hasGuest, email } = values;
    const attendeeValue = `${firstName} ${lastName} ${hasGuest ? " +1" : ""}`;

    try {
      await addDoc(collection(db, "guestlist"), {
        attendee: attendeeValue,
        email: email,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleSubmit = async (values: any) => {
    const { firstName, lastName, email, hasGuest } = values;
    addToGuestlist(values);
    try {
      setLoading(true);
      await axios.post("/api/email", {
        firstName,
        lastName,
        email,
        hasGuest,
      });
      setTimeout(() => {
        setStep(3);
      }, 1000);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <FormGroup error={formik.touched.firstName && Boolean(formik.errors.firstName)}>
          <FieldUI>
            <p>first name</p>
            <Input
              required
              id="firstName"
              name="firstName"
              type="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            />
          </FieldUI>
          {formik.touched.firstName && Boolean(formik.errors.firstName) && (
            <FormHelperText>your first name is required</FormHelperText>
          )}
        </FormGroup>
        <FormGroup error={formik.touched.lastName && Boolean(formik.errors.lastName)}>
          <FieldUI>
            <p>last name</p>
            <Input
              required
              id="lastName"
              name="lastName"
              type="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            />
          </FieldUI>
          {formik.touched.lastName && Boolean(formik.errors.lastName) && (
            <FormHelperText>your last name is required</FormHelperText>
          )}
        </FormGroup>
        <FormGroup error={formik.touched.email && Boolean(formik.errors.email)}>
          <FieldUI>
            <p>email</p>
            <Input
              required
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
          </FieldUI>
          {formik.touched.email && Boolean(formik.errors.email) && (
            <FormHelperText>enter a valid email</FormHelperText>
          )}
        </FormGroup>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Checkbox
            sx={{
              "&.Mui-checked": {
                color: "black",
              },
            }}
            id="hasGuest"
            name="hasGuest"
            value={formik.values.hasGuest}
            onChange={formik.handleChange}
          />
          <InputLabel style={{ fontFamily: "PPNeueMontreal-Bold" }}>
            i'm bringing a friend
          </InputLabel>
        </Box>
        <Button disabled={loading} type="submit">
          <RSVPButtonContent loading={loading} />
        </Button>
      </Form>
    </>
  );
};

const Form = styled.form``;

const FormGroup = styled(FormControl)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  p {
    opacity: 0.8;
  }
  &:hover p {
    opacity: 1;
  }
  @media (max-width: 360px) {
    margin-bottom: 6px;
  }
`;

const FieldUI = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;
const Input = styled(InputUI)`
  flex-grow: 1;
  &:after {
    border-color: #000 !important;
  }
  input {
    font-family: "PPNeueMontreal-Bold" !important;
  }
`;
const Checkbox = styled(CheckboxUI)`
  padding-inline: 0 32px;
`;
const FormHelperText = styled(FormHelperTextUI)`
  font-family: "PPNeueMontreal-Bold" !important;
  margin-left: auto;
`;
