import styled from "@emotion/styled";
import { Button, Paragraph, RSVPButtonContent } from "./util/common";
import { useState } from "react";
import React from "react";
import { FormikForm } from "./FormikForm";
import "./App.css";

export const RSVPSteps = (): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  return (
    <Container id="container" lastStep={step === 3}>
      {step === 1 && (
        <Step id="step-1">
          <Button className="animated-btn" style={{ margin: "0 auto" }} onClick={() => setStep(2)}>
            <RSVPButtonContent />
          </Button>
        </Step>
      )}
      {step === 2 && (
        <Step id="step-2">
          <FormikForm setStep={setStep} />
        </Step>
      )}
      {step === 3 && (
        <Step id="step-3">
          <Paragraph>
            Chimi is pleased to confirm that you're on the list for CHIMI AFTER NO SHOW party.
          </Paragraph>
          <Paragraph>
            March 1st <br /> 22h - 02h
          </Paragraph>
          <Paragraph>
            Address <br /> 7 Rue du Bourg l'Abbé
          </Paragraph>
        </Step>
      )}
    </Container>
  );
};

export const Container = styled.div<{ lastStep: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 38%;
  width: 310px;
  ${({ lastStep }) => lastStep && `width: 450px !important; max-width: 50%;`}
  text-align: center;
  padding-inline: 16px;
`;

export const Step = styled.div`
  width: 100%;
  text-align: center;
`;
