// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC2lml3WV4dNfO9J2B8EAC-zERh202NZXQ",
  authDomain: "afternoshow-1f598.firebaseapp.com",
  projectId: "afternoshow-1f598",
  storageBucket: "afternoshow-1f598.appspot.com",
  messagingSenderId: "555850221151",
  appId: "1:555850221151:web:d06df751fdc7e10391bdea"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

//******** RUN THIS FUNCTION WHEN GUESTLIST IS CLOSED ********//
// const getGuestlist = async () => {
//     const guestlist = [];
//     const querySnapshot = await getDocs(collection(db, "guestlist"));
//     querySnapshot.forEach((doc) => {
//       guestlist.push(doc.data().attendee);
//       console.log(`${doc.id}  => ${doc.data().attendee}`);
//     });
// };