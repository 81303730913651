import React from "react";
import "./App.css";
import "./font.css";
import { RSVPSteps } from "./RSVPSteps";
import Desktop from "./images/background-desktop-min.jpg";
import Mobile from "./images/background-mobile-min.jpg";

function App() {
  return (
    <div className="App">
      <div id="content">
        <RSVPSteps />
      </div>
      <img alt="chimi" className="background" id="desktop" src={Desktop} />
      <img alt="chimi" className="background" id="mobile" src={Mobile} />
    </div>
  );
}

export default App;
